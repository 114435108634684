<template>
  <section class="page-container">
    <div class="flex-sb-center">
      <div
        class="header-title-back flex hand"
        @click="() => $router.back()"
      >
        <i class="el-icon-arrow-left"></i>
        <span>
          Back
        </span>
      </div>
      <div>
        <v-button
          class="button-info"
          @click="() => $router.back()">Cancel</v-button>
        <v-button
          class="button-theme"
          @click="submitForm">Save</v-button>
      </div>
    </div>
    <div class="flex-justify header-title-back mg-t-10">
      <h2>{{clientId?'Edit':'Create'}} Client</h2>
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >

      <v-title title="Client Information"></v-title>
      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div
                class="form-item">
                <span  class="little-title">Client Type</span>
                <div class="car-type-radio">
                  <el-form-item prop="type">
                    <v-radio-group
                      v-model="formData.type"
                      :border="true"
                      :list="userTypeOptions"
                    >
                    </v-radio-group>
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
            >

            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
      <div class="address-content">
        <policy-holder
          ref="policy-holder"
          :form="holder_data"
          has-address-title
          not-valid
          :form-data-lock="formDataLock"
          :client-type="formData.type"
          @getFormData="getSubFormData"
        />
      </div>
      <v-title title="Setting"></v-title>
      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Blacklisted User</span>
                <div class="car-type-radio">
                  <el-form-item
                    ref="status"
                    prop="status"
                    :rules="rules.select">
                    <v-radio-group
                      v-model="formData.status"
                      :border="true"
                      :list="yesNo3Options"
                    >
                    </v-radio-group>
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
            <v-row-col
              v-if="formData.status==2"
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Reason</span>
                <div>
                  <el-form-item
                    ref="blacklist_reason"
                    prop="blacklist_reason"
                  >
                    <v-input
                      v-model="formData.blacklist_reason"
                      type="textarea"
                      :is-upper="false"
                      :rows="4"
                    >
                    </v-input>
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
    </el-form>
    <div class="bottom-button">
      <div>
        <v-button
          class="button-info"
          @click="() => $router.back()">Cancel</v-button>
        <v-button
          class="button-theme"
          @click="submitForm">Save</v-button>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import policyHolder from '@/views/components/policyDetail/policyHolderForm'
import {createClient,updateClient,getClientDetail} from '@api/clients'
export default {
  name: 'clientCreate',
  components: {
    policyHolder
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
  },
  data(){
    return{
      formDataLock:0,
      formData:{
        type:1,
        status:'',
        blacklist_reason:''
      },
      holder_data:{},
      subForm: {
        'policy-holder':false,
      },
    }
  },
  computed:{
    clientId(){
      let {id} = this.$route.params
      return id
    }
  },
  mounted(){
    let {id} = this.$route.params
    if(id){
      this.network().getClientDetail()
    }
  },
  methods:{
    getSubFormData(form, data) {
      this.subForm[form] = true
      let {policy_holder_occ_id,policy_holder_title,holder_company_phone,policy_holder_first_name,policy_holder_last_name,policy_holder_mobile,policy_holder_email,policy_holder_id_no,policy_holder_id_type,policy_holder_occ,policy_holder_birth,holder_company_name,holder_company_id_no,holder_company_email,holder_company_nature_id,holder_company_nature,holder_contact_name,holder_contact_email,holder_contact_phone,holder_contact_position,correspondence_address} = data||{}
      console.log('policy_holder_occ',policy_holder_occ)
      let params = {
        title:policy_holder_title,
        first_name:policy_holder_first_name,
        last_name:policy_holder_last_name,
        mobile:policy_holder_mobile,
        birth:policy_holder_birth,
        email:this.formData.type==2?holder_company_email:policy_holder_email,
        id_no:this.formData.type==2?holder_company_id_no:policy_holder_id_no,
        id_type:policy_holder_id_type,
        occ_id:policy_holder_occ_id,
        company_name:holder_company_name,
        company_phone:holder_company_phone,
        contact_name:holder_contact_name,
        contact_mobile:holder_contact_phone,
        nature_id:holder_company_nature_id,
        room:correspondence_address.room,
        floor:correspondence_address.floor,
        building:correspondence_address.building,
        street:correspondence_address.street,
        district:correspondence_address.district,
        area:correspondence_address.area,
        region:correspondence_address.region
      }
      this.formData = Object.assign(this.formData, params)
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        this.$refs[key].submitForm(key)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          if(this.clientId)
            this.network().updateClient()
          else
            this.network().createClient()
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network() {
      return {
        createClient: async (params) => {
          const { data } = await createClient({
            ...this.formData,
            ...params
          })
          this.$router.back()
        },
        updateClient: async (params) => {
          const { data } = await updateClient({
            ...this.formData,
            ...params,
            client_id:this.clientId
          })
          this.$router.back()
        },
        getClientDetail: async () => {
          const { data } = await getClientDetail({
            client_id:this.clientId
          })
          let params = {
            policy_holder_title:data.title,
            policy_holder_first_name:data.first_name,
            policy_holder_last_name:data.last_name,
            policy_holder_mobile:data.mobile,
            policy_holder_birth:data.birth,
            policy_holder_email:data.email,
            policy_holder_id_no:data.id_no,
            policy_holder_id_type:data.id_type,
            policy_holder_occ_id:data.occ_id||'',
            holder_company_name:data.company_name,
            holder_company_email:data.email,
            holder_company_phone:data.company_phone,
            holder_company_nature_id:data.nature_id,
            holder_company_id_no:data.id_no,
            holder_contact_name:data.contact_name,
            holder_contact_phone:data.contact_mobile,
            correspondence_address:data.address
          }
          this.holder_data = params
          this.formDataLock = +1
          this.formData = data
        },
      }
    },
  }
}
</script>

<style scoped lang="scss">
.page-container{
  .header-title-back{
    .mg-t-10{
      margin-top: 10px;
    }
  }
  .address-content{
    max-width: 770px;
  }
  .mg-b-30{
    margin-bottom: 30px;
  }
  .bottom-button{
    //max-width: 770px;
    width: 100%;
    text-align: right;
  }
}
</style>
